import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogPostList from "../components/blogPostList"

const IndexPage = () => (
  <>
    <Layout>
      <SEO />
      <h1>IF IT AIN'T BROKE, PUSH IT!</h1>
      <BlogPostList />
    </Layout>
  </>
)

export default IndexPage
